@import './rotator-settings.css';

.rotator__item {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  &-link {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    text-decoration: none;
    font-size: 0;
    z-index: 2;
    background: #fff;
    opacity: 0;
  }
  &-text {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
    &-inner {
      height: 100%;
      vertical-align: middle;
      min-height: 400px;
      display: table-cell;
      &-block {
        width: 50%;
        display: inline-block;
        white-space: normal;
        position: relative;
        &::after {
          background: #000;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          opacity: 0.7;
          z-index: -1;
        }
        & p {
          font: 1.4em var(--font);
          color: #fff;
          padding: 0.2em 1em;
          z-index: 2;
        }
      }
    }
    &_type_bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      font: 1.4em var(--font);
      color: #fff;
      padding: 0.2em 1em;
      height: auto;
      z-index: 2;
      &::after {
        background: #000;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        opacity: 0.7;
        z-index: -1;
      }
    }
  }
}
