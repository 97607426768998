@import './rotator-settings.css';

.rotator-wrapper {
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: visible !important;
  & .fotorama__nav-wrap {
    position: relative;
    margin: -30px 0 0 0;
    top: 30px;
  }
}
