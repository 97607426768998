@import './rotator-settings.css';

.rotator-content {
  & h1 {
    font: 400 2.25em/1em var(--font);
    color: #fff;
    margin: 0;
    padding: 0.2em 0 0.2em 0;
    text-shadow: 0 1px 8px #000;
  }
  & p {
    font: 300 1.2em var(--font);
    color: #fff;
    padding: 0;
    margin: 0;
    text-shadow: 0 1px 8px #000;
  }
  & h5 {
    font: 500 0.85em/1em var(--font);
    color: #fff;
    margin: 0;
    padding: 0.45em 0.5em 0.4em 0.5em;
    background: red;
    display: inline-block;
    text-transform: uppercase;
  }
}
